<template>
    <div class="navmb">
        <div class="flex flexa">
        <el-icon color="#FF6A00" size="20" class="astyle bgshadow" @click="$router.back()"><Back /></el-icon> &nbsp; &nbsp;
        <span class="">我的文档</span>
        </div>
    </div>
    
    <div class="bgf navmb" style="margin-top: 60px;">
        <el-tabs v-model="param.draft" style="margin-bottom: 0px;" @tab-change="getList(1)">
            <el-tab-pane label="已发布文档" name="1"></el-tab-pane>
            <el-tab-pane label="草稿箱" name="2"></el-tab-pane>
        </el-tabs>
    </div>
    <div class="bgf manage1 flex flexsb" v-if="param.draft==1" style="margin-top: 80px;">
        <div class="padd20">
            <el-button type="primary" class="w115 " @click="$router.push({
                path: '/document/create'
            })">新建文档</el-button>
        </div>
        <div class="padd20">
            <el-select-v2
                v-model="param_form.userCategoryId"
                placeholder="分类选择"
                class="w240"
                @change="getLists"
                filterable
                clearable
                :loading="vselect.userDocCate.loading"
                :options="vselect.userDocCate.options"
            />
            <el-select
                @change="getLists()"
                v-model="param_form.isSystem"
                placeholder="是否被平台采纳"
                class="w175 marl10 marr10"
                clearable
            >
                <el-option
                    label="是"
                    value="1"
                />
                <el-option
                    label="否"
                    value="0"
                />
            </el-select>
            <el-select
                @change="getLists()"
                v-model="param_form.isCompany"
                placeholder="是否同步到公司"
                class="w150 marr10"
                clearable
            >
                <el-option
                    label="是"
                    value="1"
                />
                <el-option
                    label="否"
                    value="0"
                />
            </el-select>
            <el-button @click="queryList" :disabled="loading">
                <template #icon>
                    <el-icon>
                        <img style="width: 1em;height:1em" draggable="false" src="../../assets/imgs/refersh.png" />
                    </el-icon>
                </template>
            </el-button>
        </div>
    </div>
    <div class="bgf mart20">
        <el-table :data="list" v-if="param.draft==1" style="width: 100%" v-loading="loading">
          <el-table-column
            fixed
            label="文档标题"
            prop="docTitle"
            :show-overflow-tooltip="{ effect: 'light' }"
            min-width="234"
          >
            <template #="{ row }">
              <div class="flex padr14">
                <router-link class="bllk col279 block text1" :to="`/knowledgeInfo/${ row.id }`">{{ row.docTitle }}</router-link>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="140"
            prop="userCategoryName"
            label="所属个人文档分类"
          >
          </el-table-column>
          <el-table-column
            min-width="140"
            prop="companyCategoryName"
            label="所属公司文档分类"
          >
          </el-table-column>
          <el-table-column
            min-width="140"
            prop="nature"
            label="类型"
          >
            <template #="{ row }">
                {{ nature.get(row.nature) }}
            </template>
          </el-table-column>
          <el-table-column
            min-width="140"
            prop="stasus"
            label="状态"
          >
            <template #="{ row }">
                {{ stasus.get(row.stasus) }}
            </template>
          </el-table-column>
          <!-- <el-table-column
            min-width="140"
            prop="reason"
            label="拒绝原因"
          >
            </el-table-column> -->
          <el-table-column
            min-width="140"
            prop="createTime"
            label="发布时间"
          >
          </el-table-column>
          <el-table-column
            min-width="200"
            align="center"
            label="操作"
          >
            <template #default="{ row }">

                <template v-if="row.stasus == 1">
                    <el-button @click="withdrawalReview(row)" text type="primary">撤回</el-button>
                </template>
                <template v-else>
                    <el-button @click="toAdd(row)" text type="primary">编辑</el-button>
                    <el-divider direction="vertical" />
                    <el-button :loading="row.deleteLoading??false" @click="deleteRow(row)" text type="danger">删除</el-button>
                    <el-divider v-if="row.stasus==2" direction="vertical" />
                    <el-button v-if="row.stasus==2"  @click="reasonData={show:true,info:row.reason}" text type="primary">查看拒绝理由</el-button>
                </template>
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="list" class="mart80" v-else style="width: 100%" v-loading="loading">
          <el-table-column
            fixed
            label="文档标题"
            prop="docTitle"
            :show-overflow-tooltip="{ effect: 'light' }"
            min-width="234"
          >
          </el-table-column>
          <el-table-column
            min-width="140"
            prop="createTime"
            label="保存时间"
          >
          </el-table-column>
          <el-table-column
            min-width="140"
            label="文档性质"
          >
            <template #="{ row }">
                {{ nature.get(row.nature) }}
            </template>
          </el-table-column>
          <el-table-column
            min-width="140"
            align="center"
            label="操作"
          >
            <template #="{ row }">
                <el-button @click="toAdd(row)" text type="primary">编辑</el-button>
                <el-divider direction="vertical" />
                <el-button :loading="row.deleteLoading??false" @click="deleteRow(row)" text type="danger">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="h70 pad20 flexab w100 borbox">
            <p class="font10 cola4">共 {{total}} 条</p>
            <el-pagination
                v-model:currentPage="param.pageNumber"
                @update:page-size="handleSizeChange"
                @update:current-page="getList"
                :page-sizes="[10, 15, 20, 25]"
                :page-size="param.pageSize"
                layout=" sizes, prev , jumper , next"
                :total="total">
            </el-pagination>
        </div>
      </div>
      <el-dialog :close-on-click-modal="false" v-model="reasonData.show" align-center title="拒绝理由" width="520px">
        <el-form :model="reasonData" ref="submitAudit" label-width="auto" :rules="reasonData.rules">
            <el-form-item label="拒绝理由" prop="reason">
                <el-input disabled type="textarea" v-model="reasonData.info" resize="none" />
            </el-form-item>
        </el-form>

        <template #footer>
            <el-button type="primary" @click="reasonData.show=false">确定</el-button>
        </template>
    </el-dialog>
</template>
<script>

import { Back } from '@element-plus/icons-vue'
export default {
    setup() {
        return {
            nature: new Map([
                [1, '海量文档'],
                [2, '个人/公司文档'],
                [3, '个人文档'],
            ]),
            stasus: new Map([
                [1, '审核中'],
                [2, '已拒绝'],
                [3, '已通过'],
                [4, '待完善'],
            ])
        }
    },
    components: {
        Back,
    },
    data() {
        return {
            reasonData:{show:false,info:''},
            param_form: {
                userCategoryId: '',
                isSystem: '',
                isCompany: '',
            },
            param: {
                userCategoryId: '',
                isSystem: '',
                isCompany: '',
                draft: '1',
                pageNumber: 1,
                pageSize: 10

            },
            vselect: {
                userDocCate: {
                    loading: false,
                    options: []
                },
            },
            loading: false,
            list: [],
            total: 0
            
        }
    },
    methods: {
        async withdrawalReview(row) {
            try {
                row.loading = true
                const res = await this.$api.user.withdrawalReview({ id: row.id })
                if (res) {
                    this.$message.success(res.message)
                    this.getList()
                }
            } catch (e) {
                return console.log(e)
            } finally {
                row.loading = false
            }
        },
        toAdd(row) {
            this.$router.push({
                path: '/document/create',
                state: {
                    id: row.id,
                    docTitle: row.docTitle,
                    userCategoryId: row.userCategoryId,
                    companyCategoryId: row.companyCategoryId,
                }
            })
        },
        async deleteRow(row) {
            try {
                await this.$confirm(
                    "确认删除吗",
                    "信息",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                    }
                )
                row.deleteLoading = true
                const result = await this.$api.document.removeDoc({
                    id: row.id,
                })
                if (result) {
                    this.getList(1)
                }
            } catch (e) {
                return console.log(e)
            } finally {
                row.deleteLoading = false
            }
        },
        queryList() {
            this.param.userCategoryId = ''
            this.param.isSystem = ''
            this.param.isCompany = ''
            this.param_form.userCategoryId = ''
            this.param_form.isSystem = ''
            this.param_form.isCompany = ''
            console.log('--------------')
            this.getList(1)
        },
        async getLists(){
            console.log('param_form',this.param_form)
            this.param.userCategoryId = this.param_form.userCategoryId
            this.param.isSystem = this.param_form.isSystem
            this.param.isCompany = this.param_form.isCompany
            this.getList(1)
        },
        async getList(pageNumber){
            try {
                pageNumber && (this.param.pageNumber = pageNumber)
                this.loading = true
                console.log('this.param',this.param)
                const { records, total } = await this.$api.know.getMyDocList(this.param)
                console.log('records',records)
                this.list = records
                this.total = total
            } catch (e) {
                return console.log(e)
            } finally {
                this.loading = false
            }
        },
        async vselect_userDocCate() {
            try {
                this.vselect.userDocCate.loading = true
                const { records, total } = await this.$api.know.userDocCategorylist({
                    pageNumber: 1,
                    pageSize: 1000
                })
                this.vselect.userDocCate.options = records.map(item => {
                    return {
                        value: item.id,
                        label: item.categoryTitle
                    }
                })
                this.vselect.userDocCate.loading = false
            } catch (e) {
                this.vselect.userDocCate.loading = false
                this.vselect.userDocCate.options = []
            }
        },
        handleSizeChange(val) {
            this.param.pageSize = val;
            this.getList();
        },
    },
    mounted() {
        const state = history.state
        this.param_form.isCompany = state.isCompany
        this.param_form.isSystem = state.isSystem
        this.param.isCompany = state.isCompany
        this.param.isSystem = state.isSystem
        this.getList()
        this.vselect_userDocCate()
    },
}
</script>
<style scoped>
</style>